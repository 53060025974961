<div class="content" role="main">

  <!-- Highlight Card -->
  <img
      width="80"
      alt="Angular Logo"
      src="assets/ISB_transparent_logo.png"
  />
  <div class="card highlight-card card-small">
    <span style="align-items: center;">{{ title }}</span>
  </div>

  <!-- Resources -->
  <mat-card class="card-section" *ngIf="!loginDisplay">
    <mat-card-title>Welcome to Virtual Assist</mat-card-title>
    <mat-card-subtitle>To start using Chatbot please login with "Login" button on top right.</mat-card-subtitle>
  </mat-card>

  <div class="card-container" *ngIf="loginDisplay">
    <h2>Hello {{userName}}, Welcome to the Virtual Assist Chatbot.</h2>
    <p>If you have Questions please click on the Chatbot button <img
      width="30"
      alt="chatbot icon"
      src="assets/nav-ass-icon.png"/>. You may select one of the options shown or type in your query in the textbox presented and press enter to get response from the bot.</p>
  </div>

  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <title>Gray Clouds Background</title>
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>

</div>

<!-- <mat-card class="card-section" *ngIf="!loginDisplay">
    <mat-card-title>Angular single-page application built with MSAL Angular</mat-card-title>
    <mat-card-subtitle>Sign-in with Azure AD and get an ID Token</mat-card-subtitle>
    <mat-card-content>This sample demonstrates how to configure MSAL Angular to sign-in, sign-out and protect a route.</mat-card-content>
</mat-card> 

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="loginDisplay">

  
    <ng-container matColumnDef="claim">
      <th mat-header-cell *matHeaderCellDef> Claim </th>
      <td mat-cell *matCellDef="let element"> {{element.claim}} </td>
    </ng-container>
  

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> -->
    <!-- Claim Column -->
	    <!-- Value Column -->