<mat-toolbar color="primary">
  <a class="title" href="/"><img
    width="40"
    alt="ISB Logo"
    src="assets/ISB_transparent_logo.png"
  />
  <span style="padding-left: 20px">{{ title }}</span></a>

  <div class="toolbar-spacer"></div>
  <!-- <a mat-button [routerLink]="['guarded']">Guarded Component</a> -->

  <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">Login</button>
  <button mat-raised-button color="accent" *ngIf="loginDisplay" (click)="logout()">Logout</button>
</mat-toolbar>
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<!-- <footer *ngIf="loginDisplay">
  <mat-toolbar>
    <div class="footer-text">
      How did we do? <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR73pcsbpbxNJuZCMKN0lURpUOU5PNlM4MzRRV0lETkk2ODBPT0NBTEY5MCQlQCN0PWcu" target="_blank"> Share your experience with us!</a>
    </div>
  </mat-toolbar>
</footer> -->